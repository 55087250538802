<template>
  <!-- 購入要求Card -->
  <b-card no-body class="mb-5">
    <b-card-body>
      <b-card-title title-tag="h3">購入要求</b-card-title>
      <b-form-row>
        <b-col md="3" lg="2">
          <b-form-group label="購入要求数" label-for="order-count">
            <b-form-input
              id="order-count"
              v-model="customerRequest.orderCount"
              disabled
              class="text-right"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3" lg="2">
          <b-form-group label="契約合意中数" label-for="order-pending">
            <b-form-input
              id="order-pending"
              v-model="customerRequest.pendingConsentCount"
              disabled
              class="text-right"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <base-table
        v-bind:fields="customerRequest.fields"
        v-bind:items="customerRequest.list"
        v-bind:default-sort="customerRequest.defaultSort"
      />
      <p v-if="customerRequest.list == 0" class="text-center text-caccent">
        購入要求がありません。
      </p>
    </b-card-body>
  </b-card>
</template>

<script>
import BaseTable from '@/components/common/BaseTable'
export default {
  components: {
    BaseTable,
  },
  props: ['customerRequest'],
}
</script>
