<template>
  <!--  wfp情報表示 -->
  <b-card no-body class="mb-5">
    <b-card-body class="bg-light">
      <b-form-row class="mb-3">
        <b-col>
          <p class="lead">
            {{ wfpInfo.actorCode }} {{ wfpInfo.name }}
          </p>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md="6" lg="3">
          <base-input
            v-bind:value="wfpInfo.period"
            label="研究活動期間"
            id="select-period"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>
        <b-col md="6" lg="3">
          <base-input
            v-bind:value="wfpInfo.representor"
            label="研究代表者"
            id="select-representor"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>
        <b-col md="6" lg="3">
          <base-input
            v-bind:value="wfpInfo.dataHandler"
            label="情報取扱い責任者"
            id="select-data-handler"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>
        <b-col md="6" lg="3">
          <base-input
            v-bind:value="wfpInfo.email"
            label="情報取扱い責任者メールアドレス"
            id="select-email"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md="3" lg="2">
          <base-input
            v-bind:value="wfpInfo.totalCooperationCount"
            label="連携済み総数"
            id="select-total-cooperation"
            text-class="text-right"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>
        <b-col md="3" lg="2">
          <base-input
            v-bind:value="wfpInfo.totalPendingCooperationCount"
            label="連携待ち総数"
            id="select-total-pending"
            text-class="text-right"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>
        <b-col md="3" lg="2">
          <base-input
            v-bind:value="wfpInfo.totalPendingCancelCount"
            label="連携解除待ち総数"
            id="select-total-pending-cancel"
            text-class="text-right"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col offset-sm="4" sm="4" offset-md="6" md="3" offset-lg="8" lg="2">
          <b-button
            v-b-modal="'update-modal'"
            variant="csub"
            class="w-100 mb-3 mb-sm-0"
            v-on:click="openUpdate()"
          >
            PLR+情報編集
          </b-button>
          <div v-if="isUpdateModalActive">
            <update-modal
              v-bind:wfp-info="wfpInfo"
              v-on:close-modal="closeUpdate($event)"
            />
          </div>
        </b-col>
        <b-col sm="4" md="3" lg="2">
          <message-button
            v-bind:target-info="wfpInfo"
            v-bind:target="1"
            v-on:message-sent="$emit('message-sent')"
          ></message-button>
        </b-col>
      </b-form-row>
    </b-card-body>
  </b-card>
</template>

<script>
import BaseInput from '@/components/common/BaseInput'
import MessageButton from '@/components/common/MessageButton'
import UpdateModal from '@/components/wf-provider/UpdateModal'
export default {
  props: ['wfpInfo'],
  components: {
    BaseInput,
    MessageButton,
    UpdateModal,
  },
  data() {
    return {
      isUpdateModalActive: false,
    }
  },
  methods: {
    openUpdate() {
      console.log('** open update modal **')
      this.isUpdateModalActive = true
    },
    closeUpdate(event) {
      // 編集画面を閉じたとき
      console.log('** close update modal **', event)
      this.isUpdateModalActive = false
      if (event) {
        // 更新していれば親へ通知
        this.$emit('info-updated', event)
      }
    },
  },
}
</script>
