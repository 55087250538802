<template>
  <!-- ワークフローステート管理Card -->
  <b-card no-body class="mb-5">
    <b-card-body>
      <b-card-title title-tag="h3">ワークフロー一覧・ステート管理</b-card-title>
      <div class="text-csub">{{ message }}</div>
      <b-form-row>
        <b-col offset-sm="8" sm="4" offset-md="9" md="3" offset-lg="10" lg="2">
          <b-button
            variant="csub"
            class="w-100 mb-3"
            v-on:click="updateState()"
          >
            <b-icon icon="check2-square"></b-icon>
            ステート更新
          </b-button>
        </b-col>
      </b-form-row>
      <b-table
        v-bind:items="localItems"
        v-bind:fields="fields"
        responsive
        bordered
        sticky-header
        head-variant="dark"
      >
        <template v-slot:cell(updateStateId)="row">
          <b-select v-model="row.item.updateStateId">
            <option
              v-for="state in updateStateOptions"
              v-bind:value="state.id"
              v-bind:key="state.id"
            >
              {{ state.state }}
            </option>
          </b-select>
        </template>
      </b-table>
      <p v-if="items.length == 0" class="text-center text-caccent">
        ワークフローがありません。
      </p>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  props: ['items', 'fields', 'states', 'providerId'],
  data() {
    return {
      localItems: [],
      message: null,
      isUpdate: false,
    }
  },
  created() {
    this.setLocalItems(this.items)
  },
  watch: {
    items(val) {
      console.log('** watch items ***')
      if (this.isUpdate) {
        this.isUpdate = false
      } else {
        // 一覧から別のwfpを選択し直したとき
        this.message = null
      }
      // localItemsを更新
      this.setLocalItems(val)
    },
  },
  computed: {
    updateStateOptions() {
      // state変更用リスト
      let copystates = JSON.parse(JSON.stringify(this.states))
      copystates.unshift({ id: null, state: '' })
      return copystates
    },
  },
  methods: {
    setLocalItems(orgItems) {
      // 元のリストから更新用ステートの項目追加 これを変更
      let copyItems = JSON.parse(JSON.stringify(orgItems))
      for (let i = 0; i < copyItems.length; i++) {
        copyItems[i].updateStateId = null
      }
      this.localItems = copyItems
    },
    updateState() {
      // 変更したステートに更新
      // 変更ステートがセットされていて、元と同じでないものが対象
      const targetItems = this.localItems.filter(
        (item) =>
          item.updateStateId !== null &&
          item.updateStateId !== item.localStateId
      )
      if (targetItems.length == 0) {
        alert('変更するステートがありません。')
        return
      }
      let updateList = []
      let messageList = []
      for (let i = 0; i < targetItems.length; i++) {
        // request用
        updateList.push({
          workflowCode: targetItems[i].workflowCode,
          stateId: targetItems[i].updateStateId,
        })
        // 更新後のメッセージ用
        messageList.push(targetItems[i].name)
      }
      this.$axiosToken
        .put('/api/wf_providers/' + this.providerId + '/wf_states', {
          updateList: updateList,
        })
        .then((response) => {
          console.log('* state update *', response)
          this.message = 'ステートの更新が完了しました。' + messageList
          this.isUpdate = true
          // 一覧更新のため親へ通知
          this.$emit('state-update')
        })
        .catch((error) => {
          console.log(error)
          this.message = null
          alert('ステートの更新に失敗しました。')
        })
    },
  },
}
</script>
