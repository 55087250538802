<template>
  <!--  wfp検索フォーム -->
  <b-form v-on:submit.prevent="onSearchClick()">
    <b-form-row>
      <b-col md="3" lg="2">
        <base-input
          v-model="search.actorCode"
          label="ActorCode"
          id="search-actor-code"
          pattern="^[0-9]+$"
          title="数値のみ"
        >
        </base-input>
      </b-col>
      <b-col md="6" lg="6">
        <base-input v-model="search.name" label="名称*" id="search-name">
        </base-input>
      </b-col>
      <b-col md="3" lg="2">
        <base-select
          v-model="search.cooperation"
          label="被験者連携"
          id="search-cooperation"
          v-bind:options="cooperationList"
        >
        </base-select>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col md="6" lg="3">
        <base-input
          v-model="search.representor"
          label="研究代表者*"
          id="search-representator"
          description="組織ステートメント項目"
        >
        </base-input>
      </b-col>
      <b-col md="6" lg="3">
        <base-input
          v-model="search.dataHandler"
          label="情報取扱い責任者*"
          id="search-data-handler"
        >
        </base-input>
      </b-col>
      <b-col md="6" lg="3">
        <base-input
          v-model="search.email"
          label="情報取扱い責任者メールアドレス*"
          id="search-email"
        >
        </base-input>
      </b-col>
      <b-col md="6" lg="3">
        <base-input
          v-model="search.period"
          label="研究活動期間*"
          id="search-period"
          description="組織ステートメント項目"
        >
        </base-input>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col offset-sm="4" sm="4" offset-lg="5" lg="2">
        <b-button
          type="submit"
          variant="csub"
          class="w-100"
          v-bind:disabled="disabled"
        >
          <b-icon icon="search"></b-icon>
          検索
        </b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import BaseInput from '@/components/common/BaseInput'
import BaseSelect from '@/components/common/BaseSelect'
import textGettable from '@/mixins/textGettable'
export default {
  components: {
    BaseInput,
    BaseSelect,
  },
  mixins: [textGettable],
  props: ['disabled'],
  data() {
    return {
      cooperationList: [],
      search: {
        actorCode: null,
        name: null,
        representor: null,
        dataHandler: null,
        email: null,
        period: null,
        cooperation: null,
      },
    }
  },
  created() {
    // 被験者連携select用
    this.cooperationList = JSON.parse(
      JSON.stringify(this.constants.cooperationStatus)
    )
    this.cooperationList.unshift({ value: null, text: '' })
  },
  methods: {
    onSearchClick() {
      this.$emit('search-click', this.search)
    },
  },
}
</script>
