<template>
  <!-- ワークフロープロバイダー管理 -->
  <base-layout v-bind:title="title">
    <!-- PLR基盤との同期 -->
    <template v-slot:pxr>
      <sync-pxr
        v-bind:is-processing="isPxrProcessing"
        v-bind:sync-date="pxrSyncDate"
        v-on:sync-click="syncPxrData()"
      />
    </template>
    <!-- 検索 -->
    <template v-slot:search>
      <search-form
        v-bind:disabled="isPxrProcessing"
        v-on:search-click="getSearchResult"
      />
    </template>
    <!-- 検索結果一覧 -->
    <template v-slot:list>
      <search-result
        v-bind:fields="wfpListFields"
        v-bind:items="wfpList"
        v-bind:message="listMessage"
        v-bind:target="pageId"
        v-on:row-selected="getDetail($event, (isUpdate = false))"
      />
    </template>
    <!-- 詳細 -->
    <template v-slot:detail v-if="wfpSelected">
      <!-- 情報 -->
      <information
        v-bind:wfp-info="wfpInfo"
        v-on:message-sent="getDetail(wfpSelected, (isUpdate = true))"
        v-on:info-updated="
          getDetail(wfpSelected, (isUpdate = true), (setListItems = true))
        "
      />
      <!-- ワークフロー一覧・ステート管理 -->
      <wf-list
        v-bind:fields="wfListFields"
        v-bind:items="wfList"
        v-bind:states="states"
        v-bind:provider-id="wfpSelected.id"
        v-on:state-update="getDetail(wfpSelected, (isUpdate = true))"
      />
      <!-- 購入要求 -->
      <request-list v-bind:customer-request="customerRequest" />
      <!-- メッセージ履歴 -->
      <message-list v-bind:messages="messages" />
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from '@/components/common/BaseLayout'
import SearchForm from '@/components/wf-provider/SearchForm'
import SearchResult from '@/components/common/SearchResult'
import Information from '@/components/wf-provider/Information'
import WfList from '@/components/wf-provider/WfListCard'
import RequestList from '@/components/wf-provider/RequestListCard'
import MessageList from '@/components/common/MessageListCard'
import SyncPxr from '@/components/common/SyncPxrData'
import { convertTimezone, getPxrSyncDate } from '@/mixins/utility'
export default {
  components: {
    BaseLayout,
    SearchForm,
    SearchResult,
    Information,
    WfList,
    RequestList,
    MessageList,
    SyncPxr,
  },
  mixins: [convertTimezone, getPxrSyncDate],
  data() {
    return {
      title: 'ワークフロープロバイダー',
      pageId: 1,
      // ***** 検索結果wfp一覧 *****
      wfpList: [],
      wfpListFields: [
        { key: 'check', label: '', tdClass: 'text-center' },
        { key: 'actorCode', label: 'ActorCode' },
        { key: 'name', label: '名称', tdClass: 'cell-break' },
        { key: 'period', label: '活動期間' },
        {
          key: 'cooperationCount',
          label: '連携済み総数',
          tdClass: 'text-right',
        },
        {
          key: 'pendingCooperationCount',
          label: '連携待ち総数',
          tdClass: 'text-right',
        },
        {
          key: 'pendingCancelCount',
          label: '連携解除待ち総数',
          tdClass: 'text-right',
        },
        { key: 'representor', label: '研究代表者' },
        { key: 'dataHandler', label: '情報取扱い責任者' },
        { key: 'email', label: '情報取扱い責任者メールアドレス' },
        { key: 'button', label: '連絡', tdClass: 'text-center' },
      ],
      wfpSelected: null,
      listMessage: null,
      // ***** 選択wfp詳細 *****
      // wfp情報
      wfpInfo: {
        id: null,
        actorCode: null,
        name: null,
        representor: null,
        dataHandler: null,
        email: null,
        period: null,
        totalCooperationCount: null,
        totalPendingCooperationCount: null,
        totalPendingCancelCount: null,
      },
      // ワークフロー一覧
      wfList: [],
      wfListFields: [
        { key: 'workflowCode', label: 'wfCode' },
        { key: 'codeWithCheckDigit', label: '+ C/D' },
        { key: 'name', label: 'ワークフロー' },
        { key: 'regionName', label: 'Region' },
        {
          key: 'cooperationCount',
          label: '連携済み',
          tdClass: 'text-right',
        },
        {
          key: 'pendingCooperationCount',
          label: '連携待ち',
          tdClass: 'text-right',
        },
        {
          key: 'pendingCancelCount',
          label: '連携解除待ち',
          tdClass: 'text-right',
        },
        { key: 'localState', label: 'ステート' },
        { key: 'updateStateId', label: 'ステート変更', tdClass: 'text-center' },
      ],
      states: [],
      // 購入要求
      customerRequest: {
        orderCount: null,
        pendingConsentCount: null,
        list: [],
        fields: [
          { key: 'id', label: '契約ID', tdClass: 'text-center' },
          { key: 'contractState', label: '契約ステータス' },
          { key: 'name', label: '契約名' },
          { key: 'customerActorCode', label: 'ActorCode' },
          { key: 'customerName', label: '会員名' },
          { key: 'providerState', label: 'プロバイダー状況' },
          {
            key: 'minParticipants',
            label: '募集人数（最小）',
            tdClass: 'text-right',
          },
          {
            key: 'maxParticipants',
            label: '募集人数（最大）',
            tdClass: 'text-right',
          },
          {
            key: 'numOfConsents',
            label: '被験者許諾人数',
            tdClass: 'text-right',
          },
        ],
        defaultSort: { by: 'id', desc: true },
      },
      // メッセージ履歴
      messages: [],
      // PLR基盤との同期
      isPxrProcessing: false,
      pxrSyncDate: null,
    }
  },
  created() {
    // 最終同期日時取得
    this.getPxrSyncDate(this.pageId).then((ret) => {
      this.pxrSyncDate = this.convertTimezone(ret, false)
    })
  },
  methods: {
    syncPxrData() {
      // PLR基盤とのデータ同期
      console.log('** pxr data sync **')
      this.isPxrProcessing = true
      // 表示されているものはクリア
      this.wfpList = []
      this.wfpSelected = null

      this.$axiosToken
        .post('/api/update/all')
        .then((response) => {
          console.log(response)
          // 同期日時取得
          this.getPxrSyncDate(this.pageId).then(
            (ret) => (this.pxrSyncDate = this.convertTimezone(ret, false))
          )
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            console.log(error.response)
            alert(error.response.data.detail)
          } else {
            alert('PLR基盤からのデータ取得に失敗しました。')
          }
        })
        .finally(() => {
          this.isPxrProcessing = false
        })
    },
    getSearchResult(searchItems) {
      // ワークフロープロバイダー一覧取得
      console.log('searchItems', searchItems)
      this.listMessage = null
      this.$axiosToken
        .get('/api/wf_providers', {
          params: {
            actor_code: searchItems.actorCode,
            name: searchItems.name,
            representor: searchItems.representor,
            data_handler: searchItems.dataHandler,
            email: searchItems.email,
            period: searchItems.period,
            cooperation: searchItems.cooperation,
          },
        })
        .then((response) => {
          console.log('** wfp list **', response)
          this.wfpList = response.data.providerList
          if (this.wfpList.length == 0) {
            this.listMessage = '該当するワークフロープロバイダーがありません。'
          }
        })
        .catch((error) => {
          console.log(error)
          this.wfpList = []
          this.listMessage = 'ワークフロープロバイダーの取得に失敗しました。'
        })
    },
    getDetail(selectedRow, isUpdate, setListItems = false) {
      // 選択されたwfpの詳細取得 isUpdate true:更新 false:行選択で新たに取得
      // setListItems=true:一覧を再取得せず表示の値を更新しておく
      console.log('** get detail **', selectedRow, isUpdate)
      if (!isUpdate && !selectedRow) {
        this.wfpSelected = null
        console.log('*** 選択解除')
        return
      }
      // -- state一覧get --
      this.$axiosToken
        .get('/api/wf_states')
        .then((response) => {
          console.log('** get state list **', response)
          this.states = response.data.stateList
        })
        .catch((error) => {
          console.log(error)
        })
      // -- wfp詳細get --
      this.$axiosToken
        .get('/api/wf_providers/' + selectedRow['id'])
        .then((response) => {
          console.log('** get wfp detail **', response)
          this.wfpInfo.id = response.data.id //info
          this.wfpInfo.actorCode = response.data.actorCode //info
          this.wfpInfo.name = response.data.name //info
          this.wfpInfo.representor = response.data.representor //info
          this.wfpInfo.dataHandler = response.data.dataHandler //info
          this.wfpInfo.email = response.data.email //info
          this.wfpInfo.period = response.data.period //info
          this.wfpInfo.totalCooperationCount =
            response.data.totalCooperationCount //info
          this.wfpInfo.totalPendingCooperationCount =
            response.data.totalPendingCooperationCount //info
          this.wfpInfo.totalPendingCancelCount =
            response.data.totalPendingCancelCount //info
          this.wfList = response.data.workflowList //wf
          this.customerRequest.orderCount = response.data.orderCount //contract
          this.customerRequest.pendingConsentCount =
            response.data.pendingConsentCount //contract
          this.customerRequest.list = response.data.orderList //contract
          this.messages = response.data.messageLog //message
          // 日付項目をLocalTimeに変換
          for (let i = 0; i < this.messages.length; i++) {
            this.messages[i].date = this.convertTimezone(
              this.messages[i].date,
              false
            )
          }
          if (!isUpdate) {
            this.wfpSelected = selectedRow
          }
          if (setListItems) {
            const targetRow = this.wfpList.find((r) => r.id === this.wfpInfo.id)
            targetRow['dataHandler'] = this.wfpInfo.dataHandler
            targetRow['email'] = this.wfpInfo.email
          }
        })
        .catch((error) => {
          console.log(error)
          // 詳細表示ありで行選択でエラーの場合はクリア 更新の時は表示したままにする
          if (isUpdate) {
            alert('ワークフロープロバイダー詳細の更新に失敗しました。')
          } else {
            this.wfpSelected = null
            alert('ワークフロープロバイダー詳細の取得に失敗しました。')
          }
        })
    },
  },
}
</script>
