<template>
  <!-- wfp情報更新モーダル画面 -->
  <b-modal
    id="update-modal"
    size="md"
    title="PLR+情報更新"
    header-bg-variant="cmain"
    header-text-variant="white"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
  >
    <b-alert v-if="outputMsg" show variant="caccent">
      {{ outputMsg }}
    </b-alert>
    <p class="text-csub">
      「{{ wfpInfo.actorCode }} {{ wfpInfo.name }}」の情報を更新します。
    </p>
    <b-form v-on:submit.prevent="updateInfo()">
      <base-input
        v-model="dataHandler"
        label="情報取扱い責任者"
        id="data-handler"
      >
      </base-input>
      <base-input
        v-model="email"
        label="情報取扱い責任者メールアドレス"
        id="email"
      >
      </base-input>
      <b-form-row>
        <b-col offset-sm="3" sm="3">
          <b-button
            class="w-100 mb-3"
            variant="caccent"
            type="submit"
            v-bind:disabled="isUpdated"
          >
            更新
          </b-button>
        </b-col>
        <b-col sm="3">
          <b-button v-on:click="onCloseClick" class="w-100 mb-3" variant="csub">
            閉じる
          </b-button>
        </b-col>
      </b-form-row>
    </b-form>
  </b-modal>
</template>

<script>
import BaseInput from '@/components/common/BaseInput'
export default {
  components: {
    BaseInput,
  },
  props: ['wfpInfo'],
  data() {
    return {
      dataHandler: this.wfpInfo.dataHandler,
      email: this.wfpInfo.email,
      isUpdated: false,
      outputMsg: null,
      getListErr: false,
    }
  },
  methods: {
    updateInfo() {
      // wfp情報更新
      this.$axiosToken
        .put('/api/wf_providers/' + this.wfpInfo.id, {
          dataHandler: this.dataHandler,
          email: this.email,
        })
        .then((response) => {
          console.log('* update info *', response)
          this.outputMsg = '更新が完了しました。'
          this.isUpdated = true
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            console.log(error.response)
            this.outputMsg = error.response.data.detail
          } else {
            this.outputMsg = '更新に失敗しました。'
          }
        })
    },
    onCloseClick() {
      // 画面を閉じるとき更新したか返す
      this.$emit('close-modal', this.isUpdated)
    },
  },
}
</script>
